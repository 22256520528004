import { createApp } from "petite-vue";

import.meta.glob(['@images/**']);
import.meta.env.DEV && import("@styles/main.sass");

import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import SimpleLightbox from "simple-lightbox";
import tippy from "tippy.js";
import initArchiveOffer from "./archive-offer";

(() => {
    const slider = document.querySelector('[data-testimonials-slides]') as HTMLElement
    if (!slider) return

    new Swiper(slider, {
        slidesPerView: 1,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
        },

        spaceBetween: 120,
        allowTouchMove: true,
        autoHeight: false,
        modules: [Navigation],
        navigation: {
            nextEl: "[data-testimonials-next]",
            prevEl: "[data-testimonials-prev]",
        }
    })
})();

(() => {
    if (!document.querySelector('[data-gallery]'))
        return

    new SimpleLightbox({
        elements: '[data-gallery] > a'
    })
})();

(() => {
    const galleryElement = document.querySelector('.wp-block-gallery');
    if (!galleryElement)
        return;

    new SimpleLightbox({
        elements: '.wp-block-gallery .wp-block-image > a'
    });
})();


interface Header {
    opened: boolean
    handleOpenedHeader(state: boolean): void
}

const headerEl = document.querySelector('[data-header]'),
    headerScope: Header = {
        opened: false,
        handleOpenedHeader(state) {
            document.body.classList.toggle("u-hiddenOverflow", state)
        }
    }

headerEl && createApp(headerScope).mount(headerEl)

initArchiveOffer();

window.addEventListener("create-tooltip", () => {
    document.querySelectorAll('[data-tippy-content]')
        .forEach((tooltip: HTMLElement) => {
            // @ts-ignore
            tooltip.hasOwnProperty('_tippy') && tooltip._tippy?.destroy();
        })

    tippy('[data-tippy-content]', {
        arrow: false,
    })
}, false);

(() => {
    if (document.body.classList.contains('archive')) return

    document.querySelectorAll<HTMLElement>('.OfferGallery')
        .forEach(gallery => gallery.addEventListener('scroll', () => {
            gallery.classList.toggle('at-start', gallery.scrollLeft < 40)
            gallery.classList.toggle('at-end', gallery.scrollWidth - 40 - gallery.offsetWidth < gallery.scrollLeft)
        }))

    document.querySelectorAll<HTMLButtonElement>('[data-offer-gallery]')
        .forEach(button => button.addEventListener('click', () => {
            const figure = button.closest('figure')
            let offset = button.closest('figure').offsetWidth

            if (button.dataset.offerGallery === 'prev')
                offset = offset * -1

            figure.firstElementChild?.scrollBy({ top: 0, left: offset })
        }))
})()

document.addEventListener("DOMContentLoaded", () => {
    (() => {
        const btn = document.querySelector('[data-scroll-top]')
        btn?.addEventListener('click', () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))

        const toggleButtonVisibility = () => {
            btn.toggleAttribute('disabled', window.scrollY < 480)
        }

        toggleButtonVisibility()
        window.addEventListener('scroll', () => toggleButtonVisibility(), { passive: true })
    })();
})

