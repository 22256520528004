import {createApp, reactive} from "petite-vue";

declare global {
    const archiveData: ArchiveData
}

export default function initArchiveOffer(): void {
    const archiveGrid = document.querySelector('[data-offer-grid]')
    if (!archiveGrid || !archiveData) return

    const queryToSelectedParams = (query: QueryParams) => {
        return Object.values(archiveData.filterParams ?? {})
            .reduce<QueryParams>((params, parameter) => {
                const newParams = {}

                if (parameter.type === 'range') {
                    ['od', 'do'].forEach(range => {
                        const key = `${parameter.key}_${range}`
                        newParams[key] = query[key] ?? ""
                    })
                } else {
                    const isSingle = parameter.type !== 'check',
                        queryValue = query[parameter.key] as string

                    newParams[parameter.key] = isSingle ? queryValue ?? "" : queryValue?.split(",") ?? []
                }

                return {...params, ...newParams}
            }, {
                typ: query['typ'] ?? ''
            })
    }

    const archive: ArchiveStore = reactive({
        ...archiveData,
        appendPosts: false,
        scrollIntoView: false,
        isLoading: false,
        order: archiveData.query['razeni'] ?? 'nejnovejsi',
        search: archiveData.query['hledat'] ?? '',
        filteredCount: archiveData.totalCount ?? 0,
        selectedParams: queryToSelectedParams(archiveData.query),
        get selectedParamsTags() {
            let tags: FilterParamTag[] = []
            Object.entries<string | string[]>(this.selectedParams).forEach(([key, value]) => {
                const parameter = this.filterParams[key.replace('_od', ''). replace('_do', '')],
                    terms = Array.isArray(value) ? value : [value]

                parameter && terms.forEach(term => {
                    term !== '' && tags.push({
                        key: key,
                        value: term,
                        label: parameter.hasOwnProperty('options') ? parameter.options[term] ?? term
                            : parameter.type !== 'range' ? term : `${key.replace(`${parameter.key}_`, '')} ${parseInt(<string>value).toLocaleString()} ${parameter.unit.replace('m2', 'm<sup>2</sup>')}`
                    })
                })
            })

            return tags
        },

        setQuery(params = {}, resetPager = false, resetQuery = false) {
            const query = params ? {
                ...(resetQuery ? {} : this.query),
                ...params,
                ...(resetPager ? {strana: 1} : {})
            } : {}

            this.getArchive(query)
        },
        removeParameter(key: string, value: string) {
            if (!this.selectedParams.hasOwnProperty(key)) return
            this.selectedParams[key] = Array.isArray(this.selectedParams[key])
                ? this.selectedParams[key].filter((term: string) => term !== value) : []

            this.setQuery(this.selectedParams , true, true)
        },
        changeType(type: string) {
            this.setQuery({ typ: type }, true)
        },
        changeOrder() {
            this.setQuery({ razeni: this.order }, true)
        },
        changePage(page: number) {
            this.scrollIntoView = true
            this.setQuery({ strana: page })
        },
        openParametersFilter(e) {
            const target = e.target as HTMLElement
            (target?.closest('aside').querySelector('dialog'))?.showModal()
        },
        closeParametersFilter(e) {
            const target = e.target as HTMLElement
            target.closest('dialog')?.close()
        },
        handleFilterClosing(e) {
            if(this.isLoading) return

            this.search = archive.query['hledat'] ?? ''
            this.selectedParams = queryToSelectedParams(archive.query)
            this.filteredCount = this.totalCount
        },
        searchOffers() {
            this.setQuery({ hledat: this.search }, true, true)
        },
        clearSearch() {
            this.setQuery(this.selectedParams, true, true)
        },
        filterOffers() {
            this.setQuery(this.selectedParams, true, true)
        },
        prepareQueryString(queryParams = {}, type: string = '') {
            const query = new URLSearchParams()
            this.queryOrder.forEach((param: string) => {
                const value: string | string[] = queryParams[param] ?? ""

                queryParams.hasOwnProperty(param) && query.append(param, Array.isArray(value)
                    ? value.sort().join(',') : queryParams[param].toString())
            });

            query.get('strana') === '1' && query.delete('strana');
            query.get('razeni') === 'nejnovejsi' && query.delete('nejnovejsi');
            [...query.entries()].forEach(([key, value]) => {
                (value === "" || value === "default") && query.delete(key)
            })

            type?.length && query.set('return', type)

            return [...query].length ? `?${decodeURIComponent(query.toString())}` : ''
        },
        async getArchive(queryParams = {}) {
            this.isLoading = true

            const queryString = this.prepareQueryString(queryParams),
                targetUrl = `${location.pathname}${queryString}`

            await fetch(`${this.apiUrl}${queryString}`, { method: "GET" })
                .then((response) => response.json())
                .then((data) => {
                    this.offers = [...(this.appendPosts ? this.offers : []), ...data.offers]
                    this.totalCount = this.filteredCount = data.totalCount
                    this.page = data.page
                    this.pager = data.pager
                    this.nextPageUrl = data.nextPageUrl
                    this.query = data.query
                    this.search = data.query['hledat'] ?? ''
                    this.selectedParams = queryToSelectedParams(data.query)

                    document.querySelector('h1').innerText = data.title

                    this.scrollIntoView && !this.appendPosts && archiveGrid?.scrollIntoView()
                    !queryParams.hasOwnProperty('noHistory') && history.pushState({}, '', targetUrl)
                })
                .catch(e => e.name !== 'AbortError' && location.assign(targetUrl))
                .finally(() => {
                    this.isLoading = this.appendPosts = this.scrollIntoView = false
                })
        },
        async getSummary() {
            const queryString = this.prepareQueryString(this.selectedParams, 'summary')
            await fetch(`${this.apiUrl}${queryString}`)
                .then((response) => response.json())
                .then((data) => { this.filteredCount = data.count })
        }
    })

    const pager = document.querySelector('[data-pager]')
    pager?.querySelector('nav')?.addEventListener('click', e => {
        const target = e.target as HTMLElement
        if (!target.matches('a')) return

        e.preventDefault()
        const pageUrl = new URLSearchParams((new URL(target.getAttribute('href'))).search)
        archive.changePage(parseInt(pageUrl.get('strana') ?? "1"))
    })

    archiveGrid.addEventListener('scroll', e => {
        const target = e.target as HTMLElement
        if (!target.matches('.OfferGallery')) return

        target.classList.toggle('at-start', target.scrollLeft < 40)
        target.classList.toggle('at-end', target.scrollWidth - 40 - target.offsetWidth < target.scrollLeft)
    }, true)

    archiveGrid.addEventListener('click', e => {
        const target = e.target as HTMLElement
        if (!target.matches('[data-offer-gallery]')) return

        const figure = target.closest('figure')
        let offset = target.closest('figure').offsetWidth

        if (target.dataset.offerGallery === 'prev')
            offset = offset * -1

        figure.firstElementChild?.scrollBy({ top: 0, left: offset })
    })

    createApp({ archive }).mount(archiveGrid)
}
